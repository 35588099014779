import * as React from 'react';
import { Link, graphql } from 'gatsby';
import { Typography, Grid } from '@material-ui/core';
import styled from 'styled-components';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import ContentfulRichText from '../components/contentfulRichText';
import { ProjectQuery } from '../../types/graphql-types';
import Container from '../components/Container';

const WrapLink = styled(Link)`
  text-decoration: none;
`;

const PostImage = styled.img`
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
`;

type Props = {
  data: ProjectQuery;
};

const Portfolio: React.FC<Props> = ({ data }: Props) => {
  const documents = data.allContentfulProject.edges.map(edge => {
    return edge.node;
  });
  return (
    <Layout>
      <SEO title="Portfolio" />
      <div style={{ marginTop: '6rem' }} />

      <Container variant="wide">
        <Typography variant="h1">Portfolio</Typography>
        {documents.map(node => {
          return (
            <div key={node.id}>
              <Typography variant="h2" key={`${node.id}-title`}>
                {node.title}
              </Typography>
              {node.featuredImage ? (
                <Grid item key={node.slug} xs={12} md={7}>
                  <WrapLink to={`/portfolio/${node.slug}`}>
                    <PostImage
                      src={node.featuredImage.fluid.src}
                      srcSet={node.featuredImage.fluid.srcSet}
                    />
                  </WrapLink>
                </Grid>
              ) : null}
              {/* <Typography variant="body1">{node.excerpt}</Typography> */}
              <ContentfulRichText
                document={node.json}
                key={`${node.id}-content`}
              />
            </div>
          );
        })}
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query Portfolio {
    allContentfulProject(limit: 10) {
      edges {
        node {
          id
          title
          slug
          updatedAt
          featuredImage {
            fluid {
              src
              srcSet
            }
          }
          body {
            json
          }
        }
      }
    }
  }
`;

export default Portfolio;
